import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { TerminalContextProvider } from "react-terminal";






const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: [
      'bahij',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',') + '!important'
  },
  palette: {
    primary: {
      main: '#e25555',
    }
  },
});


// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });






ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <StylesProvider jss={jss}>
      <TerminalContextProvider >
      <div dir="ltr">
        <App />
      </div>
      </TerminalContextProvider>
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
