import React from 'react';
// import {Link as LinkReact } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Layout from './Layout'
import { Box } from '@mui/material';
import './App.css';
import {BrowserRouter as Router, Routes as Switch,Route,Link as LinkReact} from "react-router-dom";
import { Platform } from './configs'
function Copyright() {
  return (
    <Typography variant="subtitle2" color="textSecondary" align="center">
      {'© '}
      <Link color="inherit" dir="ltr" href="https://modn.xyz/">
      ≡ Mod(N)
      </Link>{' '}
      {new Date().getFullYear()}
      {' , '}
      {'All Right Reserved.'}

    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  head: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: '#FFF'
  },
  main: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
  },
  customLink:{
    color:'inherit',
    textDecoration:'none',
    '&:hover':{
      textDecoration:'none'
    }
  }
}));



export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
      <Typography component="h1" className={classes.head}  variant="h2" align="center">
        <LinkReact to='/' className={classes.customLink}>
        
          {Platform.name}
        </LinkReact>
      </Typography>

      <Typography component="h6" align="center">
      By {' '}

      <Link  href="https://modn.xyz" dir="ltr" color="primary">
        ≡ Mod(N)
        </Link>
        </Typography>
        
        
        
      
          <Switch>
              
            <Route exact path="/faq" element={<Layout page="faq"/>}>
                
            </Route>
            <Route path='*' element={ <Layout page=""/>}>
            </Route>
            <Route exact path="/" element={<Layout page="home"/>}></Route>
          </Switch>
        
      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Box
              variant="subtitle2" 
              sx={{
                '*':{
                  marginRight: 2,
                }
              }}
              textAlign="center"
              style={{marginBottom: 2,paddingBottom:2.5}}
            >
            <LinkReact to='/faq' color="textSecondary" className={classes.customLink}>
            FAQ
            </LinkReact>
            {/* <LinkReact to='/terms-and-privacy' color="textSecondary" className={classes.customLink}>
              
              سياسة الاستخدام والخصوصيّة
            </LinkReact> */}
          </Box>
          <Copyright />
        </Container>
      </footer>
      </Router>
    </div>
  );
}
