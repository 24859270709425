import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Regexs } from '../configs';
import { getDatabase, push, ref } from "firebase/database";
import{ init as emailjs, send as sendMail } from '@emailjs/browser';
import Faq from "react-faq-component";


export default function FAQ() {


    const data = {
        title: "TODO",
        rows:[],
    };

    
    const styles = {
        // bgColor: 'white',
        titleTextColor: "#e25555",
        // rowTitleColor: "blue",
        rowContentColor: 'rgba(0, 0, 0, 0.6)',
        arrowColor: "#e25555",
        
    };
    
    const config = {
        // animate: true,
        // arrowIcon: "V",
        tabFocus: true
    };
    
    
    React.useEffect(() => {
        const body = document.querySelector('#root');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 1000)

    }, [])

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} align="center">
                    <Typography variant="h5" gutterBottom align="center">
                        <span color="primary" style={{color:'#e25555'}}> FAQ </span>
                    </Typography>
                    
                    

                </Grid>
                <Grid item xs={8} style={{margin: '0 auto'}}>
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}