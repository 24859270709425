import React from 'react';
import {Link as LinkReact } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

export default function NotFound() {

    
    
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item md={12} align="center">
                    <Typography variant="h5" gutterBottom align="center">
                        <span color="primary" style={{color:'#e25555'}}> أوبس! </span>
                        لا شيء هنا؟ يا إلهي..
                    </Typography>
                    <Typography variant="body1" color="textSecondary" gutterBottom align="center">
                        ما رأيك في أن تعود؟ .. لابد أن تعود.. مطرٌ مطرٌ مطر
                    </Typography>
                    <LinkReact to="/">
                        <Button
                            style={{marginTop:'2rem'}}
                            variant="contained"
                            color="primary"
                            >
                                حسنٌ سأعود
                        </Button>
                    </LinkReact>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}