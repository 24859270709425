import React from 'react';
import {Link as LinkReact } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@mui/material';
import Link from '@material-ui/core/Link';
import Lands from './Lands'
// import { App } from '../components/terminal/app'

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor:'transparent'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  
  mt2: {
    marginTop: theme.spacing(2),
  }
}));

export default function Home() {


    const classes = useStyles();
    
    
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    
    
    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom align="center">
                        Execute
                        <span color="primary" style={{color:'#e25555'}}>  binaries </span>
                        online.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" gutterBottom align="center">
                    Handed me the source, or ship the binary, both are acceptacble. To maximize your benifits, read about &nbsp;
                      <Link  href="//nixos.org" dir="ltr" color="primary" target={'_blank'}>
                        Nix Project
                      </Link>.

                    </Typography>
                </Grid>
                
                <Grid item container xs={12} >

                    <Lands />
                    
                </Grid>
            </Grid>
        </React.Fragment>
    );
}