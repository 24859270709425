import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Home from './pages/Home'
import NotFound from './pages/NotFound'
// import UnderConstructure from './pages/UnderConstructure'
import FAQ from './pages/FAQ'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    backgroundColor:'transparent'
  },
}));


function Page(name) {
  switch (name) {
    case "home":
      return <Home />


    case "faq":
      return <FAQ />


      default:
        return <NotFound />
  }
}

export default function Layout(props) {
  const classes = useStyles();


  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper} elevation={0}>
          {Page(props.page)}
        </Paper>
      </main>
    </React.Fragment>
  );
}
