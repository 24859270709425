import React from 'react';
import {Link as LinkReact } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { List, TextField } from '@mui/material';
import { ReactTerminal } from "react-terminal";
import {Box} from '@mui/material';
const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
    backgroundColor:'transparent'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  
  mt2: {
    marginTop: theme.spacing(2),
  }
}));

export default function Lands() {


    const classes = useStyles();
    
    
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    // Define commands here
    const commands = {
        help: (command)=>{
            return `Hello From Help! ${command}`
        },
        cd: (commands)=>{
          return `workdir ${commands[0]}`
        },
        ls: (command)=>{
          return `List directories`
        },
        get: (commands) => {
          var commands = commands.split(" ");
          return `From ${commands[0]} .. Clone ${commands[1]}`
        },
        go: (commands) => `Result: ${commands}`,
        npm: (commands) => `Result: ${commands}`,
        npx: (commands) => `NPX`,
        node: (commands) => `Hello node`
    };
    
    return (
        <React.Fragment>
          <Box                             style={{width: '80%',margin:'0 auto'}}>
            

            <Grid container item sm={12}
                style={{alignItems:'center',justifyContent:'center',display:'flex'}}
                >
                {/* <ReactTerminal commands={commands} showControlBar={true} theme="material-dark" showControlButtons={false}/> */}
                <iframe src="https://hello.modn.dev:7681/" class="terminal-container"/>

            </Grid>
            <Box>
            <p><b>Note:</b> still expermintal. Feel free to contact us on <a href="mailto:hello@modn.xyz"><code style={{color:'#e25555',padding:2.5}}>hello@modn.xyz</code></a>
            
            {/* or <LinkReact to="/faq" style={{color:'#e25555'}}>learn more</LinkReact> */}
            .
            </p>

            </Box>
          </Box>
        </React.Fragment>
    );
}